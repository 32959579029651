import { Link } from 'gatsby'
import React from 'react'
import './style.scss'

const Footer = ({ author, title }) => (
  <div className="footer">
      <p>
        <span>#LIKE A <u>LOCAL</u></span>
        <span>#LIKE A <u>LOCAL</u></span>
        <span>#LIKE A <u>LOCAL</u></span>
        <span>#LIKE A <u>LOCAL</u></span>
        <span>#LIKE A <u>LOCAL</u></span>
        <span>#LIKE A <u>LOCAL</u></span>
        <span>#LIKE A <u>LOCAL</u></span>
        <span>#LIKE A <u>LOCAL</u></span>
        <span>#LIKE A <u>LOCAL</u></span>
        <span>#LIKE A <u>LOCAL</u></span>
        <span>#LIKE A <u>LOCAL</u></span>
        <span>#LIKE A <u>LOCAL</u></span>
        <span>#LIKE A <u>LOCAL</u></span>
        <span>#LIKE A <u>LOCAL</u></span>
        <span>#LIKE A <u>LOCAL</u></span>
        <span>#LIKE A <u>LOCAL</u></span>
        <span>#LIKE A <u>LOCAL</u></span>
        <span>#LIKE A <u>LOCAL</u></span>
        <span>#LIKE A <u>LOCAL</u></span>
        <span>#LIKE A <u>LOCAL</u></span>
        <span>#LIKE A <u>LOCAL</u></span>
        <span>#LIKE A <u>LOCAL</u></span>
        <span>#LIKE A <u>LOCAL</u></span>
        <span>#LIKE A <u>LOCAL</u></span>
      </p>
  </div>
)

export default Footer
