import React from 'react'
import { Link } from 'gatsby'
import Icon from 'components/icon'

class Navi extends React.Component {
  render() {
    const { location, title } = this.props
    return (
      <nav className="navbar navbar-expand flex-column flex-md-row bg-primary">
        <div className="container">
          <div className="d-flex justify-content-between w-100">
            <Link className="text-center" to="/">
              <h1 className="navbar-brand text-black mb-0 py-0">#LIKE A <u>LOCAL</u></h1>
            </Link>
            <div className="navbar-nav-scroll d-flex align-items-center">
              <ul className="navbar-nav bd-navbar-nav flex-row">
                <li>
                  <a href="https://www.instagram.com/likealocal.to/" target="_blank"><Icon name="instagram"></Icon></a>
                </li>
                <li>
                  <a href="https://twitter.com/likealocalto" target="_blank"><Icon name="twitter"></Icon></a>
                </li>
              </ul>
            </div>
          </div>
          <div className="navbar-nav flex-row ml-md-auto d-none d-md-flex" />
        </div>
      </nav>
    )
  }
}

export default Navi
